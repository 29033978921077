
<template>
  <div>
     <LiefengContent>
       <template v-slot:title>子平台组织机构管理</template>
       <template v-slot:toolsbarRight>
            <Form class="search" :label-width="100" :inline="true">
              <FormItem  label="组织维度">
                <Select transfer style="text-align:left;width:150px" v-model="search.orgCode" placeholder="请选择组织维度"  @on-change="selectOrg" >
                  <Option :value="item.id" v-for="(item,index) in codeOptionsTwo" :key="index">{{item.name}}</Option>
                </Select>
              </FormItem>
              <FormItem label="关键字搜索">
                <Input v-model="search.name" placeholder="填输入关键字"></Input>
              </FormItem>
                <Button type="primary" icon="ios-search-outline" style="margin-left: 10px; " @click="searchBtn">搜索</Button>
                <Button type="success" icon="ios-refresh" style="margin-left: 10px;" @click="reset">重置</Button>
                <Button type="primary" style="margin-left: 10px;" @click="$router.push('/dimensionmanage')">维度管理</Button>
              <Button type="info" icon="ios-add" style="margin-left: 10px;" @click="openAddModal">新增</Button>
            </Form>
        </template>
         <!--身体部分-->
        <template v-slot:contentArea>
             <div class="container">
                 <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    v-if="showTable"
                ></LiefengTable>
             </div>
        </template>
     </LiefengContent>

     <LiefengModal class="map" :fullscreen="true" :title="newflag ?'新增机构':'修改机构'" @input="addModalFn" :value="addModal.status">
          <template #contentarea>
            <AddModal v-if="showFlag" :isShow='isShow' @changeTree='changeTree' :newflag='newflag' :codeOptions='codeOptions' :formData='formData' ref="addChild"></AddModal>
          </template>
          <template #toolsbar>
            <Button type="info" style="margin-right: 10px" @click="addModal.tip = true;addModalFn(false)">取消</Button>
            <Button type="primary" @click="addSave">确定</Button>
          </template>
        </LiefengModal>
     <LiefengModal :fullscreen="true" title="分配成员" @input="openTableFn" :value="openTableStatus">
       <template #contentarea>
         <LiefengTable
              :talbeColumns="fenpeiColumns"
              :tableData="fengpeiTable"
              :loading="fenpeiLoading"
              :fixTable="true"
              :hidePage='true'
              @tableSelect="tableSelect"
          ></LiefengTable>
       </template>
        <template #toolsbar>
            <Button type="info" style="margin-right: 10px" @click="openTableFn(false)">取消</Button>
            <Button type="primary" @click="saveTable">确定</Button>
          </template>
     </LiefengModal>
     <LiefengModal :fullscreen="false" title="菜单授权" @input="openImage" :value="openImageStatus" class="tree">
        <template #contentarea>
          <MenuTree @nodeList="getNode" ref="tree" :custGlobalId="detailCustGlobalId" :nodeList='nodeList'></MenuTree>
       </template>
       <template #toolsbar>
            <Button type="info" style="margin-right: 10px" @click="imageTip=true;openImage(false)">取消</Button>
            <Button type="primary" @click="saveImage">确定</Button>
       </template>
     </LiefengModal>
     <LiefengModal :fullscreen="true" title="部门设置" @input="openDepartment" :value="openDepartmentStatus">
        <template #contentarea>
          <DepartmentTable :dataOrgId="departmentId"></DepartmentTable>
       </template>
       <template #toolsbar>
            <Button type="primary" @click="openDepartment(false)">返回</Button>
       </template>
     </LiefengModal>
     <LiefengModal :fullscreen="true" width="800px" height="800px"  title="服务范围授权" @input="openService" :value="openServiceStatus">
        <template #contentarea>
         <Service :orgCode="serviceCode"></Service>
       </template>
       <template #toolsbar>
            <Button type="primary" @click="addService">确定</Button>
       </template>
     </LiefengModal>
     <LiefengModal :fullscreen="true" title="关联下级机构" @input="openSubordinate" :value="subordinateStatus">
       <template #contentarea>
          <Subordinate :isShow="showNumberSub" :orgCode="subOrgCode"></Subordinate>
       </template>
       <template #toolsbar>
            <Button type="primary" @click="openSubordinate(false)">确定</Button>
       </template>
     </LiefengModal>
     <LiefengModal :fullscreen="false" :width="500"
                :height="500" title="设置终端链接" @input="terminalInput" :value="terminalStatus">
       <template #contentarea>
         <Terminal @setSuccess="setSuccess"  ref="terminal" :terminalNum="terminalNum" :termianlOrgcode="termianlOrgcode" :termianOrgId="termianOrgId"></Terminal>
       </template>
       <template #toolsbar>
            <Button type="info" style="margin-right:10px" @click="terminalInput(false)">取消</Button>
            <Button type="primary" @click="saveTerminal">确定</Button>
       </template>
     </LiefengModal>
  </div>
</template>

<script>
//@route('/institutional')
import LiefengTable from "@/components/LiefengTable";
import LiefengContent from "@/components/LiefengContent3";
import LiefengModal from "@/components/LiefengModal";
import AddModal from './childrens/addmodal'
import MenuTree from './childrens/menuTree'
import DepartmentTable from './childrens/departmentTable'
import Service from './childrens/service'
import Subordinate from './childrens/subordinate'
import {terminalMixin} from './terminal'
import Terminal from './childrens/terminal.vue'
export default {
  components: {
    LiefengTable,
    LiefengContent,
    LiefengModal,
    AddModal,
    MenuTree,
    DepartmentTable,
    Service,
    Subordinate,
    Terminal
  },
  mixins:[terminalMixin],
  data() {
    return {
       talbeColumns: [
        {
          title:'组织名称',
          key:'orgName',
          minWidth:200,
          align:'center'
        },
        
        // {
        //   title:'组织机构ID',
        //   key:'orgId',
        //   minWidth:200,
        //   align:'center'
        // },
        // {
        //   title:'组织机构父ID',
        //   key:'parentId',
        //   minWidth:200,
        //   align:'center'
        // },
        // {
        //   title:'维度ID',
        //   key:'dimensionId',
        //   minWidth:200,
        //   align:'center'
        // },
        {
          title: '组织机构编码',
          key: 'orgCode',
          minWidth: 100,
          align: "center",
        },
        {
          title: '级别',
          key: 'orgLevel',
          minWidth: 100,
          align: "center",
        },
        {
          title: '组织属性',
          key: 'orgType',
          minWidth: 150,
          align: "center",
          render : (h,params) =>{
            return h ('div',{},params.row.orgType == '1' ?'机构' : params.row.orgType == '2' ? '部门' :'')
          }
        },
        {
          title: '所属组织维度',
          key: 'dimensionName',
          minWidth: 150,
          align: "center",
        },
        {
          title:'授权组织范围数量',
          minWidth:150,
          align:'center',
          key:'orgScopeCount'
        },
        {
          title:'oem编码',
          minWidth:150,
          align:'center',
          key:'oemCode'
        },
        {
          title: '状态',
          minWidth: 150,
          align: "center",
          fixed:'right',
           render: (h, params) => {
            return h("div", [
              h("i-switch", {
                props: {
                  value: params.row.status,
                  size: "large",
                  "true-value": "1",
                  "false-value": "2",
                  "before-change": this.beforeChangeSwitch,
                },
                scopedSlots: {
                  open: () => h("span", "启用"),
                  close: () => h("span", "禁用"),
                },
                on: {
                  "on-change": (val) => {
                    this.changeSwitch(val, params.row);
                  },
                },
              }),
            ]);
          }, 
   
        },
        {
          title: '创建时间',
          key: 'gmtCreate',
          minWidth: 150,
          align: "center",
          render : (h,params) =>{
            return h ('div',{},params.row.gmtCreate ?params.row.gmtCreate = this.$core.formatDate(
                new Date(params.row.gmtCreate),
                "yyyy-MM-dd hh:mm:ss"
              ):'')
          }
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          align: "center",
          minWidth:200,
          render: (h, params) => {
            return h("div", [
              h(
                "Dropdown",
                {
                  props: {
                    transfer: true,
                  },
                },
                [
                  h(
                    "Button",
                    {
                      props: {
                        type: "info",
                        size: "small",
                        icon: "ios-arrow-down",
                      },
                    },
                    "编辑"
                  ),
                  h(
                    "DropdownMenu",
                    {
                      slot: "list",
                    },
                    [
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.formData = {
                                  dimensionId:'',
                                    orgName:'',
                                    parentId:'',
                                    seq:0,
                                    orgDesc:'',
                                    addr:{
                                      addr:'',
                                      longitude:'',
                                      latitude:'',
                                      cityCode: "",
                                      countyCode: "",
                                      provinceCode: "",
                                      streetCode:'',
                                      communityCode:'',
                                      id:''
                                    },
                                    qualification:{
                                      tel:'',
                                      legalRepresentative:'',
                                      recordCode:'',
                                      businessLicense:'',
                                      cooperationAgreement:'',
                                      creditCode:'',
                                      orgInfoId:''
                                    }
                                }
                              this.showFlag = true
                              
                              this.getDetail(params.row.orgId)
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "修改"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.goWarrant(params.row)
                              //  this.openImageStatus = true
                              //  this.detailCustGlobalId = params.row.orgCode
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "授权系统"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.openDepartmentStatus = true
                              this.departmentId = ''
                              this.departmentId = params.row.orgId
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "部门设置"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.openServiceStatus = true
                              this.serviceCode = params.row.orgCode
                              
                            },
                          },
                          style: {
                            textAlign: "center"
                          },
                        },
                        "服务范围授权"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                                this.subordinateStatus = true
                                  ++this.showNumberSub
                                this.subOrgCode = params.row.orgCode
                            },
                          },
                          style: {
                            textAlign: "center"
                          },
                        },
                        "关联下级机构"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                               this.terminalStatus = true
                               this.termianlOrgcode = params.row.orgCode
                               this.termianOrgId = params.row.orgId
                               ++this.terminalNum
                               
                            },
                          },
                          style: {
                            textAlign: "center"
                          },
                        },
                        "设置终端链接"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                             this.$Modal.confirm({
                                title: "温馨提示",
                                content: "<p>您正在删除当前数据，此操作不可逆，是否继续？</p>",
                                onOk: () => {
                                this.deleteFn(params.row.orgId)
                                },
                              })
                            },
                          },
                          style: {
                            textAlign: "center"
                          },
                        },
                        "删除"
                      ),
                    ]
                  ),
                ]
              ),
            ]);
          },
        }
      ],
      currPage:0,
       tableData:[],
       loading:false,
       page:1,
       total:0,
       pageSize:20,  
       codeOptions:[],
       search:{
         name:'',
         orgCode:'全部'
       },
       addModal:{
         status:false,
         tip:false
       },
       newflag:true,
       formData:{
         user:{
           username:'',
           mobile:'',
           password:''
         },   
           dimensionId:'',
            orgName:'',
            parentId:'',
            seq:0,
            orgDesc:'',
            addr:{
               addr:'',
               longitude:'',
               latitude:'',
               cityCode: "",
               countyCode: "",
               provinceCode: "",
                streetCode:'',
               communityCode:'',
               id:''
            },
            qualification:{
              logo:'',
              tel:'',
              legalRepresentative:'',
              recordCode:'',
              businessLicense:'',
              cooperationAgreement:'',
              creditCode:'',
              orgInfoId:''
            }
        },
      // 存放修改时候拿到的
       editRow:{},
       openTableStatus:false,
       fenpeiColumns:[
         {
           type:'selection',
           align:'center',
           width:100
         },
         {
                  title:'真实名称',
                  key:'name',
                  minWidth:200,
                  align:"center"
              },
              {
                title: "昵称",
                key: "nickName",
                minWidth: 200,
                align: "center"
            },
              {
                title: "手机号",
                  key: "mobile",
                  minWidth: 200,
                  align: "center"
              },
              {
                  title:'审核状态',
                  minWidth:200,
                  align:'center',
                  render:(h,params)=>{
                      return h ('div',{},params.row.status == 1 ?'已开通':params.row.status == 2 ?'禁止':params.row.status == 3?'等待审核':'')
                  }
              }

       ],
       fengpeiTable:[],
       fenpeiLoading:false,
       selectList:[],
       saveOrgcode:'',
      //  是否显示子组件
      showFlag:false,
      isShow:0,

      // 查看图片是否打开
      openImageStatus:false,
      imageTip:false, 
      detailImage:'',
      showTable:true,
      detailCustGlobalId:'',

      // 部门设置模态框
      openDepartmentStatus:false,
      departmentId:'',
      codeOptionsTwo:[],
      openServiceStatus:false,
      serviceCode:'',

      // 设置下级机构
      subordinateStatus:false,
      showNumberSub:0,
      subOrgCode:'',

     

    }
  },
  methods:{
    // 打开下级机构
    openSubordinate(status){
      this.subordinateStatus = status
    
    },
    // 服务范围模态框
    openService(status){
      if(!status){
        this.openServiceStatus = status
        this.serviceCode = ''
      }
    },
    // 点击保存按钮
    addService(){
      this.openServiceStatus = false
    },
    // 部门设置模态框
    openDepartment(status){
        this.openDepartmentStatus = status
      if(!status) this.departmentId = ''   
    },
    // 确定关闭
    saveImage(){
     
      let data = this.$refs.tree.$refs.tree.getCheckedAndIndeterminateNodes()
      if(data.length == 0){
        this.$Message.warning({
          content:'授权机构不能为空',
          background:true
        })
        return
      }else{
         let list = []
         data.map(item =>{
           list.push(item.id)
         })
         this.grantOrg(list.join(','))
      }
    },
    // 关闭查看图片事件
    openImage(status){
      if(!status && this.imageTip){
        this.openImageStatus = status
        this.imageTip = false
        this.detailCustGlobalId = ''
      }else{
        this.openImageStatus = status
      }
      
    },
    // 选择列表的事件
    tableSelect(val){
      // console.log(val);
      this.selectList = []
      val.map(item =>{
        // if(item._disabled == false)
        this.selectList.push(item.custGlobalId)
      })
    },
    // 分配确认按钮
    saveTable(){
      if(this.selectList.length == 0){
        this.openTableStatus = false
      }else{
        this.updateFengpei(this.saveOrgcode)
      }

    },
    // 关闭分配模态框事件
    openTableFn(status){
      this.openTableStatus = status
    },
    changeSwitch(val,item){
      let data= {
        dimensionId:item.dimensionId,
        oemCode:parent.vue.oemInfo.oemCode,
        orgName:item.orgName,
        parentId:item.parentId,
        orgDesc:item.orgDesc,
        seq:item.seq,
        orgId:item.orgId,
        orgLevel:item.orgLevel,
        status:val
      }
      this.updateStatus(data)
    },
    // 审核之前提醒
    beforeChangeSwitch() {
      return new Promise((resolve) => {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "确认是否切换状态吗？",
          onOk: () => {
            resolve();
          },
        });
      });
    },
      // 方法部分
      changeTree(val){
        // this.formData.parentId =val[0].id
      },
      // 点击弹窗确定按钮
      addSave(){
        console.log('1');
         // 新增
        // console.log('新增时候',this.$refs.addChild.$refs.liefengUploadTwo.uploadList);
        //  判断语句
          if(this.formData.orgName == ''){
            this.$Message.warning({
              content:'请输入组织名称',
              background:true
            })
            return
          }
          if(this.formData.addr.cityCode == '' || this.formData.addr.countyCode == '' || this.formData.addr.streetCode == '' || this.formData.addr.communityCode == ''){
            this.$Message.warning({
              content:'请选择地区并选择到社区',
              background:true
            })
            return
          }
        if(this.formData.dimensionId == ''){
            this.$Message.warning({
              content:'请选择所属组织维度',
              background:true
            })
            return
          }
        //   if(this.formData.parentId == ''){
        //      this.$Message.warning({
        //       content:'请选择上机组织',
        //       background:true
        //     })
        //     return
        // }
        // if(this.formData.addr.addr == ''){
        //   this.$Message.warning({
        //       content:'请输入详细地址',
        //       background:true
        //     })
        //     return
        //   }
        // if(this.formData.qualification.creditCode == ''){
        //   this.$Message.warning({
        //       content:'请输入统一社会信用代码',
        //       background:true
        //     })
        //     return
        //   }

        // if(this.$refs.addChild.$refs.liefengUpload.uploadList.length == 0){
        //     this.$Message.warning({
        //       content:'请上传营业执照',
        //       background:true
        //     })
        //     return
        // }else{
        //   this.formData.qualification.businessLicense = this.$refs.addChild.$refs.liefengUpload.uploadList[0].url
        // }
        // if(this.formData.qualification.legalRepresentative == ''){
        //   this.$Message.warning({
        //       content:'请输入法人代表',
        //       background:true
        //     })
        //     return
        //   }
        // if(this.formData.qualification.recordCode == ''){
        //   this.$Message.warning({
        //       content:'请输入备案号',
        //       background:true
        //     })
        //     return
        //   }
        if(this.$refs.addChild.$refs.logoUpload.uploadList.length != 0){
            this.formData.qualification.logo = this.$refs.addChild.$refs.logoUpload.uploadList[0].url;
        }else {
            this.formData.qualification.logo = ''
        }

        if(this.$refs.addChild.$refs.liefengUploadTwo.uploadList.length == 0){
            // this.$Message.warning({
            //   content:'请上传平台合作协议',
            //   background:true
            // })
            // return
            this.formData.qualification.cooperationAgreement = ''
        }else{
          this.formData.qualification.cooperationAgreement = this.$refs.addChild.$refs.liefengUploadTwo.uploadList[0].url
        }
        // 如果是新增的，判断管理员信息填写
        // if(this.newflag){
        //    if(this.formData.user.username == ''){
        //       this.$Message.warning({
        //           content:'请填写管理员名称',
        //           background:true
        //         })
        //         return
        //     }
        //   if(this.formData.user.mobile == ''){
        //     this.$Message.warning({
        //         content:'请填写管理员手机号',
        //         background:true
        //       })
        //       return
        //   }
        //   if(this.formData.user.password == ''){
        //     this.$Message.warning({
        //         content:'请填写管理员密码',
        //         background:true
        //     })
        //       return
        //   }
        // }
        console.log('2',this.formData);
        if(this.formData.addr && this.formData.addr.longitude && this.formData.addr.longitude != ''){
          let dataLongitude = this.formData.addr.longitude
          if(dataLongitude && dataLongitude != ''){
            if(dataLongitude.indexOf(',')>-1){
              dataLongitude = dataLongitude.split(',')
            }
            if(dataLongitude.indexOf('，')>-1){
              dataLongitude = dataLongitude.split('，')
            }
            if(dataLongitude.length != 2){
              this.$Message.warning({
                content:'请填写正确的经纬度',
                background:true
              })
              return
            }
          }
          this.formData.addr.longitude = dataLongitude[0]
          this.formData.addr.latitude = dataLongitude[1]
        }
        
        console.log('3');
        if(this.newflag){
          let data ={
              dimensionId:this.formData.dimensionId,
              orgName:this.formData.orgName,
              parentId:this.formData.parentId,
              seq:this.formData.seq,
              orgDesc:this.formData.orgDesc,
          }
          this.addCode(data)
        }else{
          // 修改
          let data = {
            dimensionId:this.formData.dimensionId,
            oemCode:parent.vue.oemInfo.oemCode,
            orgName:this.formData.orgName,
            parentId:this.formData.parentId,
            orgDesc:this.formData.orgDesc,
            seq:this.formData.seq,
            orgId:this.editRow.orgId,
            orgLevel:this.editRow.orgLevel,
          }
          this.updateDetail(data)
        }
      },
       //选择接收人弹窗状态改变
        addModalFn(status) {
            if(!status && this.addModal.tip){
                // this.$Modal.confirm({
                //     title: "温馨提示",
                //     content: "您正在关闭此窗口，信息将不会保存，是否继续",
                //     onOk: () => {
                        this.addModal.status = status;
                        this.addModal.tip = false
                        this.newflag = false
                        this.showFlag = false
                        
                    // }
                //  });
            }else{
                 this.addModal.status = status;
                //  this.showFlag = false
            }
           
        },
      // 点击搜索按钮
      searchBtn(){
        this.page = 1
        this.pageSize = 20
        this.getList()
      },
      reset(){
        this.page = 1,
        this.pageSize = 20
        this.search.name = ''
        this.search.orgCode ='全部'
        this.getList()
      },
      // 选择组织机构
      selectOrg(val){
        this.search.orgCode = val
      },
      // 点击新增按钮
      openAddModal(){
        this.addModal.status = true
        this.showFlag = true
        // this.$refs.addChild.defaultList= []
        // this.$refs.addChild.defaultTwoList= []
        this.formData = {
           user:{
             username:'',
             mobile:'',
             password:''
           },
           dimensionId:'',
            orgName:'',
            parentId:'',
            seq:0,
            orgDesc:'',
            addr:{
               addr:'',
               longitude:'',
               latitude:'',
               cityCode: "",
               countyCode: "",
               provinceCode: "",
               streetCode:'',
               communityCode:'',
               id:''
            },
            qualification:{
              tel:'',
              legalRepresentative:'',
              recordCode:'',
              businessLicense:'',
              cooperationAgreement:'',
              creditCode:'',
              orgInfoId:''
            }
        }
        this.newflag = true
      },
      // 改变分页
      hadlePageSize(val){
           this.page = val.page,
           this.pageSize = val.pageSize
           this.getList()
       },
      // 接口部分
      // 组织分页接口
      getList(){
        this.loading = true
        this.$get('/orgzz/pc/organization/selectOrganizationPage',{
          page:this.page,
          pageSize:this.pageSize,
          custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
          dimensionId:this.search.orgCode == '全部' ? '' :this.search.orgCode,
          orgCode:parent.vue.loginInfo.userinfo.orgCode,
          orgName:this.search.name,
          orgType:1,
          oemCode:parent.vue.oemInfo.oemCode,
          parentId:0
        }).then(res=>{
          if(res && res.code ==200){
            this.loading =false
            this.tableData = res.dataList
            this.total = res.maxCount
            this.currPage = res.currentPage
          }else{
            this.loading =false
            this.$Message.error({
              content:res.desc,
              background:true
            })
          }
        })
      },
      // 维度列表
      getCode(){
        this.$get('/orgzz/pc/dimension/selectAuthorizationDimension',{
          appCode:''
        },{"Content-Type": "application/json"}).then(res=>{
          this.codeOptions = []
          this.codeOptionsTwo = [{id:'全部',code:'',name:'全部'}]
          if(res.code == 200){
            res.dataList.map(item =>{
              if(item){
                this.codeOptions.push(item)
                this.codeOptionsTwo.push(item)
              }
            })
          }
          // this.codeOptions = res.dataList
        })
      },
      addCode(data){
          console.log(this.formData.qualification)
        this.$post('/orgzz/pc/organization/autoBuildOrgDetailNoToken',
          {
            dimensionId:data.dimensionId,
            oemCode:parent.vue.oemInfo.oemCode,
            orgName:data.orgName,
            parentId:0,
            orgDesc:data.orgDesc,
            seq:data.seq,
            
            addr:{
               addr:this.formData.addr.addr,
               longitude:this.formData.addr.longitude,
               latitude:this.formData.addr.latitude,
               cityCode: this.formData.addr.cityCode,
               countyCode: this.formData.addr.countyCode,
               provinceCode: this.formData.addr.provinceCode,
               streetCode:this.formData.addr.streetCode,
               communityCode:this.formData.addr.communityCode,
            },
            qualification:{
              tel:this.formData.qualification.tel,
              legalRepresentative:this.formData.qualification.legalRepresentative,
              recordCode:this.formData.qualification.recordCode,
              businessLicense:this.formData.qualification.businessLicense,
              cooperationAgreement:this.formData.qualification.cooperationAgreement,
              creditCode:this.formData.qualification.creditCode,
              logo:this.formData.qualification.logo,
            },
            // user:{
            //   username:this.formData.user.username,
            //   mobile:this.formData.user.mobile,
            //   password:this.$md5(this.formData.user.password)
            // }
          },
          {"Content-Type": "application/json"}
        ).then(res=>{
          if(res.code == 200){
            this.$Message.success({
              background:true,
              content:'新增成功'
            })
            this.addModal.status = false;
            this.addModal.tip = false
            this.page = this.currPage
            this.pageSize = 20
            this.showFlag = false
            this.getList()
            
          }else{
            this.$Message.error({
              content:res.desc,
              background:true
            })
          }
        })
      },
      // 获取详情接口
      getDetail(data){
        this.$get('/orgzz/pc/organization/selectOrgInfoByOrgId',{
          orgId:data
        },{"Content-Type": "application/json"}).then(res=>{
          if(res.code == 200){
             this.newflag = false
             this.isShow += 1
             this.editRow = res.data
             this.formData.dimensionId=res.data.dimensionId
             this.formData.orgName=res.data.orgName
             this.formData.parentId = res.data.parentId
             if(res.data.addr && res.data.addr != ''){
               this.formData.addr ={
                    addr:res.data.addr.addr,
                    longitude:(res.data.addr.longitude ? res.data.addr.longitude+',' : '' )+(res.data.addr.latitude || ''),
                    // latitude:res.data.addr.latitude,
                    cityCode: res.data.addr.cityCode,
                    countyCode: res.data.addr.countyCode,
                    provinceCode: res.data.addr.provinceCode,
                    streetCode:res.data.addr.streetCode,
                    communityCode:res.data.addr.communityCode,
                    id:res.data.addr.id
               }
             }else{
                 this.formData.addr = {}
              }
            if(res.data.qualification && res.data.qualification != ''){
                this.formData.qualification={
                  tel:res.data.qualification.tel,
                  legalRepresentative:res.data.qualification.legalRepresentative,
                  recordCode:res.data.qualification.recordCode,
                  businessLicense:res.data.qualification.businessLicense,
                  cooperationAgreement:res.data.qualification.cooperationAgreement,
                  creditCode:res.data.qualification.creditCode,
                  orgInfoId:res.data.qualification.orgInfoId,
                }
                if(res.data.qualification.logo != ''){
                    this.$refs.addChild.logoList= [{name: "", url: res.data.qualification.logo}]   
                }else {
                    this.$refs.addChild.logoList= []
                }
            }else{
              this.formData.qualification={}
            }
              if(res.data.seq){
                this.formData.seq = res.data.seq
              }else{
                this.formData.seq = 0
              }
              if(res.data.orgDesc==undefined){
                this.formData.orgDesc = ''
              }else{
                this.formData.orgDesc = res.data.orgDesc
              }
              if(res.data.qualification && res.data.qualification.businessLicense !=''){
                this.$refs.addChild.defaultList=[{name: "", url: res.data.qualification.businessLicense}]   
              }else{
                this.$refs.addChild.defaultList= []
              }
              if(res.data.qualification && res.data.qualification.cooperationAgreement !=''){
                this.$refs.addChild.defaultTwoList=[{name: "平台合作协议", url: res.data.qualification.cooperationAgreement}]   
              }else{
                this.$refs.addChild.defaultTwoList= []
              }
              this.addModal.status = true
          }else{
            this.$Message.error({
              content:data.desc,
              background:true
            })
            return
          }
        })
      },
      // 修改组织接口
      updateDetail(data){
        this.$post('/orgzz/pc/organization/updateOrganizationDetail',{
            dimensionId:data.dimensionId,
            oemCode:parent.vue.oemInfo.oemCode,
            orgName:data.orgName,
            parentId:0,
            orgDesc:data.orgDesc,
            seq:data.seq,
            orgId:data.orgId,
            orgLevel:data.orgLevel,
            addr:{
               addr:this.formData.addr.addr,
               longitude:this.formData.addr.longitude,
               latitude:this.formData.addr.latitude,
               cityCode: this.formData.addr.cityCode,
               countyCode: this.formData.addr.countyCode,
               provinceCode: this.formData.addr.provinceCode,
               streetCode:this.formData.addr.streetCode,
               communityCode:this.formData.addr.communityCode,
            },
            qualification:{
              tel:this.formData.qualification.tel,
              legalRepresentative:this.formData.qualification.legalRepresentative,
              recordCode:this.formData.qualification.recordCode,
              businessLicense:this.formData.qualification.businessLicense,
              cooperationAgreement:this.formData.qualification.cooperationAgreement,
              creditCode:this.formData.qualification.creditCode,
              
            }
        },{"Content-Type": "application/json"}).then(res=>{
          if(res.code == 200){
            this.$Message.success({
              content:'修改成功',
              background:true
            })
            this.addModal.status = false;
            this.addModal.tip = false
            this.newflag = false
            this.page = this.currPage
            this.showFlag = false
            this.getList()
            return
          }else{
            
            this.getList()
            this.$Message.error({
              background:true,
              content:res.desc
            })
            return
          }
        })
      },
      // 删除接口
      deleteFn(data){
        this.$get('/orgzz/pc/organization/delete',{
          orgId:data
        }).then(res=>{
          if(res.code == 200){
            this.$Message.success({
              content:'删除成功',
              background:true
            })
            this.page = this.currPage
            this.getList()
            return
          }else{
            this.$Message.error({
              content:res.desc,
              background:true
            })
            return
          }
        })
      },
      // 审核接口
      updateStatus(data){
        this.$post('/orgzz/pc/organization/updateOrganizationDetail',{
            dimensionId:data.dimensionId,
            oemCode:parent.vue.oemInfo.oemCode,
            orgName:data.orgName,
            parentId:data.parentId,
            orgDesc:data.orgDesc,
            seq:data.seq,
            orgId:data.orgId,
            orgLevel:data.orgLevel,
            status:data.status
        },{"Content-Type": "application/json"}).then(res=>{
          if(res.code == 200){
            this.$Message.success({
              content:'设置成功',
              background:true
            })
            this.addModal.status = false;
            this.addModal.tip = false
            this.newflag = false
            this.page = this.currPage
            this.getList()
            return
          }else{
            this.page = this.currPage
            this.showTable = false
            this.$nextTick(()=>{
              this.showTable = true
            })
            this.getList()
            this.$Message.error({
              background:true,
              content:res.desc
            })
            return
          }
        })
      },
      // 获取分配接口
      getTable(data){
        this.fenpeiLoading = true
        this.$get('/orgzz/auth/pc/TenantUserApi/selectByList',{
          orgCode:data
        }).then(res=>{
          if(res.code == 200){
            this.fenpeiLoading = false
            this.fengpeiTable = []
            this.selectList = []
            this.fengpeiTable = res.dataList
            this.fengpeiTable.map(item =>{
              if(item.selected == true){
                item._checked = true
                // item._disabled = true
                // this.selectList.push(item.custGlobalId)
              }else{
                item._checked =false
                item._disabled = false
              }
            })
          }else{
            this.fenpeiLoading = false
            this.$Message.error({
              content:res.desc,
              background:true
            })
            return
          }
        })
      },
      // 分配确认接口
      updateFengpei(data){
        this.$post('/orgzz/pc/staff/joinOrganizationAssignRole',{
          custGlobalId:this.selectList.join(','),
          orgCode:data
        }).then(res=>{
          if(res.code == 200){
            this.$Message.success({
              content:'分配成功',
              background:true
            })
            this.openTableStatus = false
            return
          }else{
            this.$Message.error({
              content:res.desc,
              background:true
            })
            return
          }
        })
      },
      // 更新授权菜单树
      grantOrg(data){
        this.$post('/old/api/pc/menu/grantOrg',{
          orgCode:this.detailCustGlobalId,
          menuIds:data
        }).then(res=>{
          if(res.code == 200){
            this.$Message.success({
              content:'菜单授权成功',
              background:true
            })
            this.openImageStatus = false
            this.imageTip = false
            this.detailCustGlobalId = ''
          }else{
            this.$Message.error({
              content:res.desc,
              background:true
            })
          }
        })
      },
      // 前往授权系统
      goWarrant(data){
        this.$router.push(`/warrantorgson?orgCode=${data.orgCode}`)
      },
  },
  created(){
    this.getList()
    this.getCode()
  }
};
</script>

<style scoped lang='less'>

// /deep/ #toolsbarRight {
//   width: 1000%;

//   .search {
//     width: 100%;
//     text-align: right;
    
//     .ivu-form-item {
//       margin-bottom: 0;
//     }
//   }
// }
.image-div{
  text-align: center;
  .image{
    display: inline-block;
    // height: 400px;
    width: 100%;
     
  }
}
.map{
  /deep/.ivu-icon-ios-close {
    display: none;
  }
}
.tree{
  /deep/#modal_contentarea{
    height: 500px;
  }
}
//编写css

</style>


